import Vue from 'vue'
import VueRouter from 'vue-router'
import store from 'vuex'

import Blank from '../pages/Blank.vue'
import Index from '../pages/Index.vue'
import Login from '../pages/auth/Login.vue'
import Signup from '../pages/auth/Signup.vue'
import FourOhFour from '../pages/utilities/FourOhFour.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Blank',
    component: Blank,
    meta: { title: 'Odyty.' }
  },
  {
    path: '/home',
    name: 'Index',
    component: Index,
    meta: { title: 'Home' }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: { title: 'Sign Up' }
  },
  {
    path: '/verify',
    name: 'Verify',
    component: Signup,
    meta: { title: 'Verify' }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { title: 'Log In' }
  },
  {
    path: '/404',
    name: '404',
    component: FourOhFour,
    meta: { title: '404' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, _from, savedPosition) {
    if (to.hash !== null) {
      const element = document.getElementById(to.meta.anchor)
      if (element !== null) {
        return window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth'
        })
      }
    } else if (savedPosition !== null) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, _from, next) => {
  if (to?.meta !== null && to.meta.title !== null) { document.title = to.meta.title }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    const str: any = store // TODO: Fix.  Disables a type error below.
    if (str.getters.isLoggedIn !== null) {
      next()
      return
    }
    next('/login')
  }

  next()
})

export default router
