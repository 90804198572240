<template>
    <header class="header">
        <!--start navbar-->
        <nav
            class="navbar navbar-expand-lg fixed-top"
            v-bind:class="{
                affix: hasAffix,
                'custom-nav': coloredLogo,
                'bg-transparent': !coloredLogo,
                'white-bg': coloredLogo,
            }"
        >
            <div class="container">
                <a class="navbar-brand" href="/"
                    ><img
                        :src="
                            coloredLogo
                                ? 'img/logo-color-1x.png'
                                : 'img/logo-white-1x.png'
                        "
                        width="120"
                        alt="logo"
                        class="img-fluid"
                /></a>

                <button
                    class="navbar-toggler"
                    type="button"
                    @click="mobileNavClicked"
                    v-bind:class="{ collapsed: collapsed }"
                >
                    <span class="ti-menu"></span>
                </button>

                <div
                    class="collapse navbar-collapse main-menu h-auto"
                    v-bind:class="{ show: !collapsed }"
                    id="navbarSupportedContent"
                >
                    <ul class="navbar-nav ml-auto">

                        <li class="nav-item">
                            <router-link
                                class="nav-link page-scroll"
                                to="/"
                                >
                                Home
                            </router-link>
                        </li>

                        <li class="nav-item nav-link page-scroll">
                            <router-link 
                                to="#about"
                                v-scroll-to="'#about'"
                                >About</router-link
                            >
                        </li>

                        <li class="nav-item">
                            <router-link
                                class="nav-link page-scroll"
                                to="#features"
                                v-scroll-to="'#features'"
                                >Features</router-link
                            >
                        </li>
                        <li class="nav-item">
                            <router-link
                                class="nav-link page-scroll"
                                to="#pricing"
                                v-scroll-to="'#pricing'"
                                >Pricing</router-link
                            >
                        </li>

                        <li class="nav-item">
                            <router-link
                                class="nav-link page-scroll"
                                to="#screenshots"
                                v-scroll-to="'#screenshots'"
                                >Screenshots</router-link
                            >
                        </li>

                        <li class="nav-item">
                            <router-link
                                class="nav-link page-scroll"
                                to="#contact"
                                v-scroll-to="'#contact'"
                                >Contact</router-link
                            >
                        </li>

                        <li
                          v-if="isLoggedIn"
                          @click.prevent="logout"
                          class="nav-item">
                            <router-link 
                                class="nav-link page-scroll"
                                to="/"
                                >
                                Log Out
                            </router-link>
                        </li>
                        <li
                          v-else
                          class="nav-item">
                            <router-link
                                class="nav-link page-scroll"
                                to="/login"
                                >
                                Log In
                            </router-link>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
        <!--end navbar-->
    </header>
</template>

<script>
export default {
    props: {
        coloredLogo: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            windowTop: 0,
            collapsed: true,
        };
    },
    mounted() {
        window.addEventListener("scroll", this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.onScroll);
    },
    methods: {
        onScroll: function () {
            this.windowTop = window.top.scrollY;
        },
        mobileNavClicked: function () {
            this.collapsed = !this.collapsed;
        },
        logout: function () {
            console.log('Performing Logout')
            this.$store.dispatch('logout')
            .then(() => {
              this.$router.push('/')
            })
         }
    },
    computed: {
        isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
        hasAffix: function () {
            return this.windowTop > 0;
        },
    },
};
</script>

