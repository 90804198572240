import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'

const cognitoUserPool = new CognitoUserPool({
  UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID ?? '',
  ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID ?? ''
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') ?? '',
    user: 'felipe@solutionnine.com'
  },
  mutations: {
    auth_request (state): void {
      state.status = 'loading'
    },
    // @ts-expect-error
    auth_success (state: any, token: string, user: string): void {
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error (state): void {
      state.status = 'error'
    },
    logout (state): void {
      state.status = ''
      state.token = ''
    }
  },
  actions: {
    async signup ({ commit }, data) {
      return await new Promise((resolve, reject) => {
        commit('auth_request')
        cognitoUserPool.signUp(data.email, data.password, [], [], function (err, result: any) {
          if (err !== null) {
            commit('auth_error', err)
            localStorage.removeItem('token')
            reject(err)
          } else {
            if (result !== null) {
              commit('auth_success', undefined, result.user.username)
              resolve(true)
            }
          }
        })
      })
    },
    async verify ({ commit, state }, data) {
      const userData = {
        Username: state.user,
        Pool: cognitoUserPool
      }
      console.log(userData)
      var cognitoUser = new CognitoUser(userData)
      return await new Promise(() => {
        cognitoUser.confirmRegistration(data.code, true, function (err, result) {
          if (err !== null) {
            alert(err.message ?? JSON.stringify(err))
            return
          }
          console.log(`call result: ${result as string}`)
        })
      })
    },
    async login ({ commit }, data) {
      const authenticationData = {
        Username: data.email,
        Password: data.password
      }
      var authenticationDetails = new AuthenticationDetails(authenticationData)
      const userData = {
        Username: data.email,
        Pool: cognitoUserPool
      }
      var cognitoUser = new CognitoUser(userData)
      return await new Promise((resolve, reject) => {
        commit('auth_request')
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: function (result) {
            const token = result.getAccessToken().getJwtToken()
            const user = data.email
            localStorage.setItem('token', token)
            axios.defaults.headers.common.Authorization = token
            commit('auth_success', token, user)
            resolve(true)
          },

          onFailure: function (err) {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          },

          mfaRequired: function (codeDeliveryDetails) {
            var verificationCode = prompt('Please input verification code', '') ?? ''
            cognitoUser.sendMFACode(verificationCode, this)
          }
        })
      })
    },
    async logout ({ commit }) {
      return await new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        delete axios.defaults.headers.common.Authorization
        resolve(true)
      })
    }
  },
  modules: {
  },
  getters: {
    isLoggedIn: state => state.token ?? false,
    authStatus: state => state.status
  }
})
