<template>
    <div>
        <div class="main">
        </div>
    </div>
</template>

<script>

export default {
    name: "Home",
    components: {},
};
</script>

<style>
</style>